<template>

   <sdPageHeader>
      <template #title>
      <a-row :gutter="30">
        <a-col>
        <div class="product-single-description">
          <sdHeading class="product-single-title" as="h1" style="margin: 15px">
           Sales Funnel Report
          </sdHeading>
          <span style="margin: 0px; font-size: 16px; font-weight: 400">
          </span>
        </div>
        </a-col>
        </a-row>
        <ProductCard
    class="list-view"
    :style="{ width: '100%', marginBottom: '10px' }"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;">
            <a-form
              name="contact"
              :rules="rules"
              :model="formState"
              @submit="handleOk"
              :layout="vertical"
            >
            <a-row :gutter="20">
            <a-col :md="4">
              <a-form-item name="center">
                <a-select
                  v-model:value="formState.center"
                  style="width: 100%"
                  showSearch
                >
                <a-select-option
                  v-for="item in branchData"
                  v-bind:key="item.branch_code"
                  :value="item.branch_code"
                >
                  {{ item.branch_code }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="4">
            <a-form-item name="team">
            <a-select
                v-model:value="formState.team"
                style="width: 100%"
                @change="onChange"
                showSearch
                >
                <a-select-option
                value="SME"
                >SME
                </a-select-option>
                <a-select-option
                value="Accounting"
                >Accounting
                </a-select-option>
            </a-select>    
            </a-form-item>
            </a-col>
            <a-col :md="4" v-if="dispInterest">
            <a-form-item name="industry">
            <a-select
                v-model:value="formState.industry"
                style="width: 100%"
                showSearch
                >
                <a-select-option
                  v-for="item in industryData"
                  v-bind:key="item.vertical_type"
                  :value="item.vertical_type"
                >
                  {{ item.vertical_type }}
                  </a-select-option>
            </a-select>    
            </a-form-item>
            </a-col>
            <!--<a-col :md="4">
              <a-form-item name="source">
                <a-select
                  v-model:value="formState.source"
                  style="width: 100%"
                  showSearch
                >
                <a-select-option
                  v-for="item in sourceData"
                  v-bind:key="item.Source_type"
                  :value="item.Source_type"
                >
                  {{ item.Source_type }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>-->
            <a-col :md="4">
            <a-form-item name="condition">
             <a-range-picker
                  style="padding-top: 10px !important"
                  @change="DateSelect"
                  :defaultValue="selectrange"
                  required
                />   
            </a-form-item>
            </a-col>
            <a-col :md="4">
            <a-form-item>
              <a-button 
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
                class="btn-add_new"
                style="margin-top: -1.5px !important;"
              >
               <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="arrow-down-circle" size="14" />  &nbsp; 
              Get Report</span>
              </a-button>
             </a-form-item>
              </a-col>
             
              </a-row>
            </a-form>
          </div>
          </ProductCard>
      </template>
     
    </sdPageHeader>
     
  <Main>
    <sdCards headless>
    <sdHeading class="product-single-title" as="h3" style="margin: 10px">
    List of Leads
    </sdHeading>
      <template v-if="selectedoption == 'Active'">
      <a-row :gutter="15" style="padding: 10px">
            
            <a-col :xs="24">
              <TopToolBox>
                <div class="product-single-description">
                  <div
                    class="
                      product-single-title
                      page-header-actions
                      product-single-description
                    "
                    style="float: right"
                  >
                    <a-button
                      class="btn-signin"
                      size="small"
                      type="default"
                      v-if="checkselected"
                      @click="bulkIgnore('B')"
                    >
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="x-circle" size="14" /> &nbsp;
                        Dismiss</span
                      >
                    </a-button>
                    
                    <a-button class="btn-signin" size="small" @click="exportToCSV()">
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="download" size="14" /> &nbsp;
                        Export</span
                      >
                    </a-button>
                  </div>
                  <span style="margin: 0px; font-size: 16px; font-weight: 400">
                    <br />
                  </span>
                </div>
              </TopToolBox>
            </a-col>
          </a-row> 
        <a-row :gutter="15">
          <a-col :md="24">
             <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                thead-class="greenColor"
                :data-source="TableData"
                :rowSelection="rowSelection"
                :columns="TableColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                    defaultPageSize: 10,
                    total: TableData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      
    </sdCards>
  </Main>
</template> 
<script>
import { ContactPageheaderStyle, Action, UserTableStyleWrapper ,ProjectSorting,TopToolBox,} from '../../style';
import { defineComponent } from 'vue';
import { Main, CardToolbox, TableWrapper,BasicFormWrapper } from '../../../styled';
import { dispatch, useStore } from 'vuex';
import { ProductCard } from "./style";
import { defineAsyncComponent, computed, reactive, ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { message, Notification } from 'ant-design-vue'; 
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default defineComponent({
  name: 'SalesReport',
  components: {
    Main,
    CardToolbox,
    BasicFormWrapper,
    UserTableStyleWrapper,
    TableWrapper,ProjectSorting,TopToolBox,ProductCard,
  },
  setup() {
    var loading = ref(true);
    var branchData = ref();
    var sourceData = ref();
    var userData = ref();
    var industryData = ref();
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var selectedoption = ref('Active');
    var selectrange = ref(null);
    var dispInterest = ref(false);
    const { dispatch, state } = useStore();
    const selectedChange = ref(null);
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT; 
    state.CurrentData.data = [];
    onMounted(() => {
    getdata();
    //dailyData();
    }); 
    const formState = reactive({
      center: "Select Branch",
      team: "Select Team",
      source: "Select Data Source",
      industry: "Select Industry"
      });
    const rules = {
      center: [
        {
          required: true,
          message: "Please select criteria first",
          trigger: "blur",
        },
      ],
      };
    const DateSelect = (date, dateString) => {
      selectrange.value = dateString;
    };
    const handleOk = (values) => {
      dailyData();
    };
    const onChange=(change)=> {
     selectedChange.value=change;
     if(selectedChange.value=="SME"){
     dispInterest.value=true;
     }
     else{
      dispInterest.value=false;
     }
    }
    const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows; 
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + ' Rows Selected';
        }
      },
     
    };
  const getdata = () => {
      loading.value = true;
      dispatch("manualFieldsData").then(
        (response) => {
          if (response.status == 200) {
            industryData.value = response.data[0];
            branchData.value = response.data[3];
            sourceData.value = response.data[4];
            loading.value = false;
          } else {
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          console.log(error);
          loading.value = false;
        }
      );
      
    };
    const dailyData = () => {
    if(formState.center=="Select Branch" || formState.team=="Select Team" || selectrange.value==null){
    alert("Please fill all required form fields: Branch, Team & Date Range");
    }
    else{
     var range = selectrange.value.toString().split(",");
     let startdate = new Date(range[0]);
     let enddate = new Date(range[1]);
     startdate = startdate.toLocaleDateString("en-CA");
     enddate = enddate.toLocaleDateString("en-CA");
        let api_data = [];
        api_data['center'] = formState.center;
        api_data['team'] = formState.team;
        api_data['source'] = formState.source;
        api_data['startdate'] = startdate;
        api_data['enddate'] = enddate;
        api_data['industry'] = formState.industry;
        
        loading.value = true;
        dispatch("salesFunnelReport",api_data).then(
            (response) => {
                if (response.status == 200) {
                    state.CurrentData.data = response.data;
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Report Loaded.",
                    });
                    loading.value = false;
                } else {
                    loading.value = false;
                    //onCancel();
                }
            },
            (error) => {
                loading.value = false;
                state.CurrentData.data = [];
                Notification["error"]({
                message: "Error!",
                description: "Error Loading Leads",
            });
          } 
          
        );
        }
    }
    const TableColumns = [
      {
        title: "Lead Id",
        dataIndex: "id",
        key: "id",
        sorter: (a, b) => a.id.length - b.id.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Company",
        dataIndex: "Company",
        key: "Company",
        sorter: (a, b) => a.Company.length - b.Company.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Contact Person",
        dataIndex: "Name",
        key: "Name",
        sorter: (a, b) => a.Name.length - b.Name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Email",
        dataIndex: "Email_Address",
        key: "Email_Address",
        sorter: (a, b) => a.Email_Address.length - b.Email_Address.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Last Called Date",
        dataIndex: "last_action_date",
        key: "last_action_date",
        sorter: (a, b) => a.last_action_date.length - b.last_action_date.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Interest",
        dataIndex: "interest",
        key: "interest",
        sorter: (a, b) => a.interest.length - b.interest.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Opportunity Amount",
        dataIndex: "opportunity_amt",
        key: "opportunity_amt",
        sorter: (a, b) => a.opportunity_amt.length - b.opportunity_amt.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Status",
        dataIndex: "current_status",
        key: "current_status",
        sorter: (a, b) => a.current_status.length - b.current_status.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Probability",
        dataIndex: "Probability",
        key: "Probability",
        sorter: (a, b) => a.Probability.length - b.Probability.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Data Source",
        dataIndex: "Lead_Source",
        key: "Lead_Source",
        sorter: (a, b) => a.Lead_Source.length - b.Lead_Source.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Lead Assigned to",
        dataIndex: "user_id",
        key: "user_id",
        sorter: (a, b) => a.user_id.length - b.user_id.length,
        sortDirections: ["descend", "ascend"],
      },
    ];
    
   
     let TableData = computed(() =>
        state.CurrentData.data.map((unreconcile) => {
          const {
            id,
            Company,
            Name,
            Email_Address,
            interest,
            opportunity_amt,
            Probability,
            current_status,
            user_id,
            last_action_date,
            last_action_status,
            Lead_Source,
            } = unreconcile;
            var linedata = [];
          return {
            key: id,
            id: id,
            Company: Company,
            Name:Name,
            interest: interest,
            opportunity_amt:opportunity_amt,
            Email_Address:Email_Address,
            Probability: Probability,
            current_status: current_status,
            user_id: user_id,
            last_action_date: last_action_date,
            last_action_status: last_action_status,
            Lead_Source: Lead_Source,
            } 
            
        }
        )
    );

const exportToCSV = () => {
  const fileName = "Sales Funnel Report";
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  let finalData = [];
  const borderStyle = {
    top: { style: "thin" },
    bottom: { style: "thin" },
    left: { style: "thin" },
    right: { style: "thin" }
  };

  const addCategoryHeader = (category) => {
    finalData.push([
      category, "", "", "", "", "", "", "", "", ""
    ]);
  };

  // Categorizing data
  const categorizedData = {
    TOFU: [],
    MOFU: [],
    BOFU: []
  };

  state.CurrentData.data.forEach((item) => {
    const category =
      item.Probability === "Cold"
        ? "TOFU"
        : item.Probability === "Warm"
        ? "MOFU"
        : "BOFU";

    categorizedData[category].push([
      item.id,
      item.Company,
      item.Name,
      item.interest,
      item.opportunity_amt,
      item.Email_Address,
      item.last_action_date,
      item.Probability,
      item.Lead_Source,
      item.current_status,
      item.user_id
      
    ]);
  });

  // Adding categorized data with headers
  Object.keys(categorizedData).forEach((category) => {
    if (categorizedData[category].length > 0) {
      addCategoryHeader(category); // Section Header
      finalData = finalData.concat(categorizedData[category]); // Append Data
      finalData.push([]); // Empty row for spacing
    }
  });

  // Define column headers
  const headers = [
    ["ID", "Company", "Name", "Interest", "Opportunity Amount", "Email","Last Contact Date","Probability","Data Source","Current Status", "User ID"]
  ];

  // Create worksheet & apply headers
  const ws = XLSX.utils.aoa_to_sheet([...headers, ...finalData]);

  // Apply borders to all cells
  const range = XLSX.utils.decode_range(ws["!ref"]);
  for (let R = range.s.r; R <= range.e.r; ++R) {
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cell_address = XLSX.utils.encode_cell({ r: R, c: C });
      if (!ws[cell_address]) continue;
      ws[cell_address].s = { border: borderStyle };
    }
  }

  // Create Workbook & Export File
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sales Funnel");

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);

  Notification["success"]({
    message: "Success!",
    description: "Downloaded."
  });
};
    return {
      loading,
      TableColumns,
      TableData,
      branchData,
      userData,
      sourceData,
      rowSelection,
      selectcount,
      checkselected,
      selectedoption,
      handleOk,
      exportToCSV,
      formState,
      selectrange,
      dispInterest,
      DateSelect,
      rules,
      onChange,
      selectedChange,
      industryData,
     };
  },
});
</script> 
<style scoped>
.ant-table-striped :deep(.table-striped) td th{
  background-color: #447316;
}
th {
  //background-color: #93BF34 !important;
}
.ant-btn-primary {
  background-color: #5C69B3 !important;
  border: 1px solid #5C69B3 !important;
}
.supp-head div{
        width: 250px;
}

.ant-col .ant-select {
  margin-top: 4px;
}
.ant-col button{
  margin-top: 5px;
}
table thead tr th:last-child {
    text-align: left !important;
}
.ant-page-header-heading {
  display: block !important;

}
</style>