<template>
   <sdPageHeader v-if="dispAssign">
      <template #title>
        <div class="product-single-description">
          <sdHeading class="product-single-title" as="h1" style="margin: 15px">
           Clicks / Opens Data
          </sdHeading>
        </div>
        <ProductCard
    class="list-view"
    :style="{ width: '100%',}"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;">
    
        <!--<BasicFormWrapper>-->
            <a-form
              name="contact"
              :rules="rules"
              :model="formState"
              @submit="handleOk"
              :layout="formState.layout"
            >
            <a-row :gutter="30">
            <a-col :md="4">
              <a-form-item name="toUser">
                <a-select
                  v-model:value="formState.toUser"
                  style="width: 100%"
                  showSearch
                >
                  <a-select-option
                  v-for="item in userData"
                  v-bind:key="item.user_id"
                  :value="item.user_id"
                  >
                  {{ item.user_id }}
                  </a-select-option>
                </a-select>
              </a-form-item>
             </a-col>
             <a-col :md="4">
             <a-form-item>
              <a-button
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
                class="btn-add_new"
                style="margin-top: -1.5px !important;"
                v-if="checkselected"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="plus-circle" size="14" /> &nbsp;
              Assign Leads</span>
              </a-button>
              </a-form-item>
              </a-col>
              </a-row>
            </a-form>

          <!--</BasicFormWrapper>-->
          
          </div>
          </ProductCard>
       </template>
   </sdPageHeader>
  <Main>
    <sdCards headless>
    <template v-if="selectedoption == 'Active'">
       <a-row :gutter="15" style="padding: 10px">
       <sdHeading class="product-single-title" as="h1" style="margin: 15px">
           Leads Data
          </sdHeading>
            <a-col :xs="24">
              <TopToolBox>
                <div class="product-single-description">
                  <div
                    class="
                      product-single-title
                      page-header-actions
                      product-single-description
                    "
                    style="float: right"
                  >
                    
                    <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                    
                  </div>
                  <span style="margin: 0px; font-size: 16px; font-weight: 400">
                    <br />
                  </span>
                </div>
              </TopToolBox>
            </a-col>
          </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
             <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                thead-class="greenColor"
                :data-source="TableData"
                :columns="TableColumns"
                :rowSelection="rowSelection"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                    defaultPageSize: 10,
                    current: currentPage,
                    total: TableData.length,
                    showSizeChanger: true,
                    pageSizeOptions: ['50', '100', '200','500','1000'],
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                    onChange: changePage
                }"

              >
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      
    </sdCards>
  </Main>
</template> 
<script>
import { ContactPageheaderStyle, Action, UserTableStyleWrapper ,ProjectSorting,TopToolBox,} from '../../style';
import { defineComponent } from 'vue';
import { Main, CardToolbox, TableWrapper } from '../../../styled';
import { dispatch, useStore } from 'vuex';
import { ProductCard } from "./style";
import { defineAsyncComponent, computed, reactive, ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { message, Notification } from 'ant-design-vue'; 
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { setItem, getItem, removeItem } from '/src/utility/localStorageControl';
export default defineComponent({
  name: 'ClickSprintForm',
  components: {
    Main,
    CardToolbox,
    UserTableStyleWrapper,
    TableWrapper,ProjectSorting,TopToolBox,ProductCard,
  },
  setup() {
    var loading = ref(true);
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var linedata = [];
    var userData = ref();
    var dispAssign = ref(false);
    var selectedoption = ref('Active');
    const { dispatch, state } = useStore();
    var supplierdrop = ref();
    var contact_id = ref(null);
    const route = useRoute();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT; 
    const orgdata = ref(JSON.parse(localStorage.getItem("syncdata")));
    const currentPage = ref(1);
    onMounted(() => {
    getdata(route.params.id);

    const savedPage = localStorage.getItem("clicksprint_current_page");
      if (savedPage) {
        currentPage.value = parseInt(savedPage);
      }
    disNav();
    getfieldsdata();
    }); 
    state.LeadsData.data = [];
    var LeadsData1 = [];
    const addFormState = reactive({
      search_text: "",
      layout: "vertical",
    });
    state.LeadsData.data = [];
    const formState = reactive({
      toUser: "Select User",
      });
const handleOk = (values) => {
      assignLeads();
    };
const disNav = () => {
  let role = JSON.parse(localStorage.getItem("usr_role"));
    if (role.role_id=="1" || role.role_id=="2") {
          dispAssign.value=true;
    } 
    else {
      dispAssign.value=false; 
    }
};
const changePage = (page) => {
  if (typeof localStorage !== "undefined") {
      localStorage.setItem("clicksprint_current_page", page);
    }
    currentPage.value = page;
};
const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows; 
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + ' Rows Selected';
        }
      },
     
    };    
const getdata = (id) => {
      loading.value = true;
      let api_data = [];
      api_data['ids'] = id;
      dispatch("getLeadsDataIndexx",api_data).then(
        (response) => {
          if (response.status == 200) {
            state.LeadsData.data = response.data;
            LeadsData1.data = response.data;
            //localStorage.setItem("search_data",JSON.stringify(response.data));
            loading.value = false;
          } else {
            state.LeadsData.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          loading.value = false;
          state.LeadsData.data = [];
        }
      );
    };
const getfieldsdata = () => {
      loading.value = true;
      dispatch("getAssignFieldsData").then(
        (response) => {
          if (response.status == 200) {
            userData.value = response.data[1];
            loading.value = false;
          } else {
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          console.log(error);
          loading.value = false;
         
        }
      );
      
    };
    const assignLeads = () => {
        let api_data = [];
        api_data['selectedid'] = selectedid;
        api_data['toUser'] = formState.toUser;
        loading.value = true;
        dispatch("assignClickLeads",api_data).then(
            (response) => {
                if (response.status == 200) {
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Leads Assigned.",
                    });
                    loading.value = false;
                } else {
                    loading.value = false;
                    //onCancel();
                }
            },
            (error) => {
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error Assigning Leads",
            });
          } 
          
        );
    }
const openurl = (id,id1) => {
      let url = '/index/';
      //localStorage.setItem("clicksprint_current_page", currentPage);
      localStorage.setItem("clicksprint_current_route", route.fullPath);
      window.open(
          url + id +'/'+id1,
          "_self",
     ); 
      
    };
    const updateComplete = (id,ids) => {
    let api_data = [];
      api_data['ids'] = id;
      api_data['source']=ids;
      dispatch("getClicksCompleteStatus",api_data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
                    message: "Succsess!",
                    description: "Clicks Completed Successfully.",
            });
          } else {
            Notification["error"]({
                message: "Error!",
                description: "Error Completing Clicks.",
            });
          }
          location.reload();
        },
        (error) => {
          Notification["error"]({
                message: "Error!",
                description: "Error Completing Clicks.",
            });
        }
      );

    };
  const handleSearch = (values) => {
  const substring = addFormState.search_text.toLowerCase();
  //const leadsData = JSON.parse(localStorage.getItem("search_data1"));
  const leadsData =LeadsData1.data;
  // Filter leadsData based on search criteria
  const filteredData = leadsData.filter(data => 
    data.Name.toLowerCase().includes(substring) || 
    data.Email_Address.toLowerCase().includes(substring) || 
    data.clicked_date.toLowerCase().includes(substring)  ||
    data.category.toLowerCase().includes(substring)  ||
    data.branch_code.toLowerCase().includes(substring)||
    data.campaign_name.toLowerCase().includes(substring)

  );
  
  // Update the state with filtered data
  state.LeadsData.data = filteredData;
};
    const TableColumns = [
    {
    title: "S.No",
    dataIndex: "serial_number",
    key: "serial_number",
    width: "10%",
    align: "center",
    sorter: (a, b) => a.serial_number - b.serial_number,
    },
     {
        title: "Company",
        dataIndex: "Company",
        key: "Company",
        sorter: (a, b) => a.Company.length - b.Company.length,
        sortDirections: ["descend", "ascend"],
        width: "10%",
      },
      {
        title: "Name",
        dataIndex: "Name",
        key: "Name",
        sorter: (a, b) => a.Name.length - b.Name.length,
        sortDirections: ["descend", "ascend"],
        width: "10%",
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        sorter: (a, b) => a.category.length - b.category.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Branch Code",
        dataIndex: "branch_code",
        key: "branch_code",
        sorter: (a, b) => a.branch_code.length - b.branch_code.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Campaign Name",
        dataIndex: "campaign_name",
        key: "campaign_name",
        sorter: (a, b) => a.campaign_name.length - b.campaign_name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Email",
        dataIndex: "Email_Address",
        key: "Email_Address",
        sorter: (a, b) => a.Email_Address.length - b.Email_Address.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        sorter: (a, b) => a.phone.length - b.phone.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Mobile",
        dataIndex: "Mobile",
        key: "Mobile",
        sorter: (a, b) => a.Mobile.length - b.Mobile.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Link",
        dataIndex: "link",
        key: "link",
        sorter: (a, b) => a.link.length - b.link.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Clicked Date",
        dataIndex: "clicked_date",
        key: "clicked_date",
        sorter: (a, b) => new Date(a.clicked_date) - new Date(b.clicked_date),
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Mail Server",
        dataIndex: "mailing_server",
        key: "mailing_server",
        sorter: (a, b) => a.mailing_server.length - b.mailing_server.length,
        sortDirections: ["descend", "ascend"],
      },
      
    ];
  let TableData = computed(() =>
        state.LeadsData.data.map((unreconcile, index) => {
          const {
            id,
            Company,
            Name,
            Email_Address,
            current_status,
            Action,
            campaign_name,
            mailing_server,
            clicked_date,
            category,
            branch_code,
            link,
            Mobile,
            phone,
            lead_id,
            } = unreconcile;
          return {
            key: lead_id,
            serial_number: index + 1,
            id: id,
            lead_id: lead_id,
            Email_Address:Email_Address,
            category: category,
            branch_code: branch_code,
            Company: (
            <div class="table-actions">
              <>
                <a style="text-align: left !important;"onClick={() => openurl(lead_id,id)} to="#">
                  <span>
                    {Company}
                    </span>
                </a>
              </>
            </div>
          ),
            link:link,
          complete: (
            <div class="table-actions">
              <>
                <a onClick={() => updateComplete(id,route.params.id)} to="#">
                  <span style="display: inline-flex !important; align-items: center !important;">
                    <sdFeatherIcons
                      type="trash-2"
                      size="22"
                      color="#1890ff"
                    />{" "}
                    &nbsp;Dispose
                  </span>
                </a>
              </>
            </div>
          ),
            Name:Name,
            current_status: current_status,
            Action: Action,
            campaign_name:campaign_name,
            mailing_server:mailing_server,
            clicked_date:clicked_date,
            Mobile:Mobile,
            phone:phone,
            } 
        }
        )
    );
  return {
      loading,
      rowSelection,
      TableColumns,
      TableData,
      selectcount,
      checkselected,
      selectedoption,
      getdata,
      openurl,
      supplierdrop,
      orgdata,
      route,
      addFormState,
      handleSearch,
      LeadsData1,
      updateComplete,
      formState,
      userData,
      handleOk,
      assignLeads,
      dispAssign,
      disNav,
      currentPage,
      changePage,
    };
  },
});
</script> 
<style scoped>
.ant-table-striped :deep(.table-striped) td th{
  background-color: #447316;
}
th {
  //background-color: #93BF34 !important;
}
.ant-btn-primary {
  background-color: #5C69B3 !important;
  border: 1px solid #5C69B3 !important;
}
.supp-head div{
        width: 250px;
}

.ant-col .ant-select {
  margin-top: 4px;
}
.ant-col button{
  margin-top: 5px;
}
table thead tr th:last-child {
    text-align: left !important;
}
</style>